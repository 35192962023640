@import "normalize.css";
@import "foundation/base";
@import 'sal.js/dist/sal.css';
/* Foundation */
@import "foundation/variables";
@import "foundation/mixins";
/* Layouts */
@import "layout/footer";
@import "layout/nav";
/* Components */
// @import "objects/components/";
// @import "objects/pages/";

// TODO: autoprefixer for css grid

.l-body-color {
  background-color: $color-purple;
}

.c-header {
  box-sizing: border-box;
  height: 100vh;
  min-height: 1024px;
  background-color: $color-purple;
  width: 100%;
  padding-top: $size-nav-height;
  padding: 400px 0;
  box-sizing: border-box;
}

.c-header__inner {
  max-width: $size-max-width;
  height: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 300px auto;
  grid-column-gap: 80px;
  grid-template-rows: auto;
  @include align-items(center);
  .inner-left {
    text-align: center;
  }
  .inner-right {
  }
  .title {
    font-size: 4rem;
    font-weight: 900;
    margin: 0 auto 40px;
    line-height: 1.2;
    color: #fff;
    font-family: 'Inter-Black', sans-serif;
  }
}

@include mq-tablet {
  .c-header__inner {
    .title {
      font-size: $font-size-xxlarge;
    }
  }
};

@include mq-mobile {
  .c-header {
    // height: auto;
    grid-template-columns: auto;
    min-height: inherit;
    padding: 0 16px;
    padding-top: $size-nav-height-mobile;
    align-items: center;

  }
  .c-header__inner {
    padding-top: 40%;
    height: auto;
    grid-template-columns: auto;
    align-items: flex-start;
    grid-row-gap: 24px;
    .logo {
      max-width: 180px;
    }
    .inner-right {
      height: 100%;
    }
    .inner-left {
      height: 100%;
    }
    .title {
      margin: auto;
      font-size: $font-size-base;
      max-width: 220px;
      text-align: center;
      br {
        display: none;
      }
    }
  }
};

.l-section {
  padding: $size-section-padding 0;
  box-sizing: border-box;
  min-height: 1024px;
  height: 1024px;
  height: 100vh;
  display: block;
  position: relative;
  @include align-items(center);
  &:before {
    content: "";
    max-width: $size-max-width;
    height: 1px;
    left: 0;
    right: 0;
    top: 60px;
    position: absolute;
    margin: auto;
    background-image: linear-gradient(to right, #fff 33%, transparent 0%);
    background-position: bottom;
    background-size: 8px 4px;
    background-repeat: repeat-x;
  }

  &:after {
    content: "";
    max-width: $size-max-width;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    margin: auto;
    background-image: linear-gradient(to right, #fff 33%, transparent 0%);
    background-position: bottom;
    background-size: 8px 4px;
    background-repeat: repeat-x;
  }

  &--pink {
    background-color: $color-black;
    border-left: 8px solid $color-pink;
    border-right: 8px solid $color-pink;
    &:before {
      background-image: linear-gradient(
        to right,
        $color-pink 33%,
        transparent 0%
      );
    }
    &:after {
      background-image: linear-gradient(
        to right,
        $color-pink 33%,
        transparent 0%
      );
    }
  }
  &--purple {
    background-color: #fff;
    border-left: 8px solid $color-purple;
    border-right: 8px solid $color-purple;
    position: relative;
    .l-section__inner {
      position: relative;
      z-index: 1;
    }
    .block {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 350px;
      z-index: 0;
      margin: auto;
      content: "";
      display: block;
      background-color: $color-purple;
    }
    &:before {
      background-image: linear-gradient(
        to right,
        $color-purple 33%,
        transparent 0%
      );
    }
    &:after {
      background-image: linear-gradient(
        to right,
        $color-purple 33%,
        transparent 0%
      );
    }
  }
  &--blue {
    background-color: $color-black;
    border-left: 8px solid $color-blue;
    border-right: 8px solid $color-blue;
    &:before {
      background-image: linear-gradient(
        to right,
        $color-blue 33%,
        transparent 0%
      );
    }
    &:after {
      background-image: linear-gradient(
        to right,
        $color-blue 33%,
        transparent 0%
      );
    }
  }
  &--yellow {
    background-color: #fff;
    border-left: 8px solid $color-yellow;
    border-right: 8px solid $color-yellow;
    &:before {
      background-image: linear-gradient(
        to right,
        $color-yellow 33%,
        transparent 0%
      );
    }
    &:after {
      background-image: linear-gradient(
        to right,
        $color-yellow 33%,
        transparent 0%
      );
    }
  }
}

@include mq-tablet {
  .l-section {
    height: auto;
    min-height: inherit;
    width: 100%;
    padding: $size-section-padding-mobile 0;
    &:before {
      width: 100%;
      top: 24px;
    }
    &:after {
      width: 100%;
      bottom: 24px;
    }
    &--purple {
      .block {
        display: none;
      }
    }
  }
};

.l-section__inner {
  max-width: $size-max-width;
  margin: auto;
  .section-heading-m {
    display: none;
  }
  &--split {
    display: grid;
    height: 100%;
    grid-template-columns: 144px auto auto;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    @include align-items(center);
    > div {
      display: block;
    }
    .section-heading {
      display: block;
      height: auto;
      width: 144px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &--table {
    display: grid;
    grid-template-columns: 144px auto;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    height: 100%;
    @include align-items(center);
    .section-heading {
      height: auto;
      width: 100%;
    }
  }
  &--photo {
    display: grid;
    grid-template-columns: 144px 360px auto;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    @include align-items(center);
    height: 100%;
    img {
      height: auto;
      width: 100%;
      display: block;
    }
    .position {
      line-height: 64px;
      background-color: $color-blue;
      width: 100%;
      color: $color-black;
      font-size: $font-size-medium;
      padding: 0 24px;
      box-sizing: border-box;
      text-align: center;
      span {
        font-weight: bold;
        padding-left: 16px;
      }
    }
    .bio {

    }
  }
  .title-text {
    font-size: $font-size-large;
    color: #fff;
    line-height: $line-height-default;
    font-weight: normal;
    margin: 0 auto 12px;
    &--dark {
      color: $color-black;
      font-weight: bold;
    }
  }
  .detail-text {
    color: #fff;
    font-size: $font-size-default;
    line-height: $line-height-default;
    margin: auto;
    opacity: 0.6;
  }
}

@include mq-tablet {
  .l-section__inner {
    max-width: 500px;
    .section-heading {
      display: none;
    }
    .section-heading-m {
      display: block;
      margin: auto;
      max-width: 100%;
    }
    &--split {
      grid-template-columns: auto;
      grid-row-gap: 40px;
      padding: 0 16px;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .title-text {
      font-size: $font-size-medium;
    }
    &--photo {
      grid-template-columns: auto;
      grid-row-gap: 40px;
      padding: 0 16px;
      .photo {
        max-width: 240px;
        margin: auto;
      }
      .position {
        font-size: $font-size-default;
        padding: 0 16px;
        line-height: 48px;
      }
    }
    &--table {
      grid-template-columns: auto;
      grid-row-gap: 40px;
      padding: 0 16px;
    }
  }
};

.c-icon-list {
  list-style: none;
  margin: auto;
  padding: 0;
  li {
    display: grid;
    grid-template-columns: 48px auto;
    grid-template-rows: auto;
    grid-column-gap: 12px;
    color: #fff;
    margin-bottom: 24px;
    align-items: center;
    .title {
      letter-spacing: 0.4em;
      text-transform: uppercase;
      font-size: $font-size-base;
      font-weight: 300;
      margin: 0 auto 8px;
      line-height: $line-height-default;
      font-family: 'Inter-Regular', sans-serif;
    }
    .detail {
      font-size: $font-size-default;
      opacity: 0.6;
      margin: auto;
    }
  }
  &--service {
    li {
      color: $color-black;
      .title {
        @include font-default;
        font-weight: bold;
        font-size: $font-size-base;
        margin: auto;
        letter-spacing: 0.2em;
      }
    }
  }
}

@include mq-mobile {
  .c-icon-list {
    li {
      align-items: flex-start;
      grid-template-columns: 36px auto;
      .title {
        font-size: $font-size-default;
        margin-bottom: 4px;
      }
    }
  }
};

.c-data-list {
  max-width: 860px;
  display: block;
  margin: auto;
  padding: 24px 0 48px;
  list-style: none;
  background-color: $color-black;
  border-radius: 4px;
  color: #fff;
  li {
    font-size: $font-size-default;
    border-top: 1px solid rgba(#fff, 0.2);
    line-height: 48px;
    padding: 0 60px;
    display: grid;
    grid-template-columns: 140px auto;
    grid-template-rows: auto;
    a {
      color: $color-yellow;
    }
    .title {
      opacity: 0.6;
    }
    .detail-multi {
      line-height: $line-height-default;
      padding: 16px 0;
    }
  }
}

.c-data-list__title {
  font-size: $font-size-large;
  font-weight: bold;
  color: #fff;
  padding-left: 60px;
}

@include mq-mobile {
  .c-data-list {
    width: auto;
    padding: 24px 0 0;
    li {
      grid-template-columns: auto;
      padding: 8px 16px;
      line-height: $line-height-default;
      .detail-multi {
        line-height: 1.8;
        padding: 8px 0;
      }
    }
  }
  .c-data-list__title {
    font-size: $font-size-medium;
    padding-left: 16px;
    margin-top: 0;
  }
};
