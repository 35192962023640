.l-nav {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}

.l-nav__inner {
  height: $size-nav-height;
  max-width: $size-max-width;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
  border-bottom: 1px solid rgba(#fff, 0.2);
  @include align-items(center);
  align-items: center;
  .align-right {
    display: grid;
    justify-content: end;
  }
  .nav-logo {

  }

  a.nav-contact {
    display: block;
    max-width: 160px;
    background-color: #fff;
    border-radius: 2px;
    line-height: 56px;
    padding: 0 24px;
    text-align: center;
    text-decoration: none;
    color: $color-purple;
    transition: all 0.25s;
    &:hover {
      color: #fff;
      background-color: rgba(#fff, 0.2);
    }
  }
}
@include mq-tablet {
  .l-nav {
    padding: 0 16px;
    box-sizing: border-box;
  }
  .l-nav__inner {
    height: $size-nav-height-mobile;
    a.nav-contact {
      line-height: 44px;
      font-size: $font-size-default;
      padding: 0 16px;
    }
  }
};
