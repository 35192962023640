.l-footer {
  height: auto;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  box-sizing: border-box;
}

.l-footer__inner {
  max-width: $size-max-width;
  margin: 0 auto 80px;
  position: relative;
  .title {
    font-size: $font-size-xxlarge;
    font-weight: 900;
    text-align: center;
    font-family: 'Inter-Black', sans-serif;
  }
  .button {
    text-align: center;
    color: #fff;
    font-size: $font-size-default;
    line-height: 56px;
    display: block;
    max-width: 160px;
    margin: auto;
    background-color: $color-purple;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.25s;
    &:hover {
      background-color: rgba($color-purple, 0.1);
      color: $color-purple;
    }
  }
}

.l-footer__lower {
  width: 100%;
  background-color: $color-purple;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  p {
    margin: auto;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: $font-size-small;
    opacity: 0.6;
  }
}

.l-footer__logo {
  margin: 0 auto -320px;
  max-width: 900px;
  display: block;
  position: relative;
}

@include mq-tablet {
  .l-footer {
    height: auto;
    padding-top: $size-section-padding-mobile;
  }
  .l-footer__inner {
    .title {
      font-size: $font-size-xlarge;
      padding: 0 24px;
    }
  }
  .l-footer__logo {
    position: relative;
    left: inherit;
    right: inherit;
    display: block;
    margin-top: 80px;
    margin: 80px auto -60px;
    width: 80%;
  }
};
