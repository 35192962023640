/* Font sizes */
$font-size-xxlarge: 3rem; // 48px
$font-size-xlarge: 2.25rem; // 36px
$font-size-large: 1.5rem; // 24px
$font-size-medium: 1.25rem; // 20px
$font-size-base: 1rem; // 16px
$font-size-default: 0.875rem; // 14px
$font-size-small: 0.75rem; // 12px
$font-size-smaller: 0.625rem; // 10px

/* Line Heights */
$line-height-default: 1.6em;
$line-height-compact: 1.3em;

/* Units */
$size-nav-height: 96px;
$size-nav-height-mobile: 64px;
$size-max-width: 1180px;
$size-col-width: 1060px;
$size-section-padding: 120px;
$size-section-padding-mobile: 64px;

/* Colors */
$color-purple: #6336C1;
$color-pink: #FF64A5;
$color-yellow: #FEC047;
$color-blue: #2BD8FE;
$color-black: #1D1D27;

/* Mixins */
@mixin mq-mobile {
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin mq-tablet {
  @media only screen and (max-width: 786px) {
    @content;
  }
}

@mixin mq-desktop {
  @media only screen and (min-width: 641px) {
    @content;
  }
}

@mixin align-items($alignment) {
  > * {
    align-self: $alignment;
  }
}

@mixin font-default {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体",
    "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}
